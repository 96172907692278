import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import {
	AlertController,
	IonRouterOutlet,
	LoadingController,
	NavController,
	NavParams,
	Platform,
	ToastController,
} from "@ionic/angular";
import { ApiProvider } from "src/app/providers/api/api";
import { Conexao, ConexaoProvider } from "src/app/providers/conexao";
import { ServiceProvider } from "src/app/providers/service-provider";
import { SplashScreen } from "@awesome-cordova-plugins/splash-screen/ngx";
import { Chart } from "chart.js";
import { Permissoes, PermissoesProvider } from 'src/app/providers/config-permissoes';

@Component({
	selector: "app-home",
	templateUrl: "./home.component.html",
	styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
	conexao: Conexao;
	parametros: any = {};
	plataforma: string;
	permissoes: Permissoes;
	exibirPagina: boolean = false;

	// eslint-disable-next-line @typescript-eslint/member-ordering
	@ViewChild("barCanvasFatVendedor") barCanvasFatVendedor: any;
	// eslint-disable-next-line @typescript-eslint/member-ordering
	@ViewChild("doughnutCanvasFatSituacao") doughnutCanvasFatSituacao: any;
	// eslint-disable-next-line @typescript-eslint/member-ordering
	@ViewChild("doughnutCanvasFatPagamento") doughnutCanvasFatPagamento: any;
	// eslint-disable-next-line @typescript-eslint/member-ordering
	@ViewChild("doughnutCanvasFatTipo") doughnutCanvasFatTipo: any;

	barChart: any;
	doughnutChart: any;

	mesAno: string;
	dataAtual: any;

	nomeEmpresa: string;

	despesaTotal: any = 0;
	totalFaturamentoVendasOs: number = 0;
	totalItensOs: any = 0;
	totalItensVenda: any = 0;
	osFaturadas: any;
	receitaTotal: any = 0;
	vendasFaturadas: any = 0;
	faturamentoTotal: any = 0;

	totalBonificacao: any = 0;
	totalCondicional: any = 0;
	totalD: any = 0;
	totalEntregaFutura: any = 0;
	totalOrcamento: any = 0;
	totalV: any = 0;

	totalSitA: any = 0;
	totalSitC: any = 0;
	totalSitE: any = 0;

	totalBoleto: any = 0;
	totalCartaoCredito: any = 0;
	totalCartaoDebito: any = 0;
	totalCheque: any = 0;
	totalCrediario: any = 0;
	totalDepositoCaixa: any = 0;
	totalDepositoDinheiro: any = 0;
	totalDepositoPixLoja: any = 0;
	totalDepositoPixParticular: any = 0;
	totalDinheiro: any = 0;
	totalPagamentoBoleto: any = 0;
	totalSiteBoleto: any = 0;
	ticketMedio: any = 0;

	situacao: boolean = false;
	ctps = [];

	osMes = [];
	produtosMaisDevolvidos = [];
	produtosMaisVendidos = [];
	vendasMes = [];
	vendedores = [];
	nomesVendedores = [];
	valoresPorVendedor = [];
	backgroundColor = [];
	backgroundHoverColor = [];

	constructor(
		public navCtrl: NavController,
		public navParams: NavParams,
		public platform: Platform,
		public loadingCtrl: LoadingController,
		public formBuilder: FormBuilder,
		public alertCtrl: AlertController,
		public service: ServiceProvider,
		public toast: ToastController,
		private splashscreen: SplashScreen,
		private conexaoProvider: ConexaoProvider,
		private api: ApiProvider,
		private ionRouterOutlet: IonRouterOutlet,
		private permissoesProvider: PermissoesProvider
	) {
		this.splashscreen.show();
		this.splashscreen.hide();

		if (platform.is("ios")) {
			this.plataforma = "ios";
		} else if (platform.is("android")) {
			this.plataforma = "android";
		}
	}

	ngOnInit() {
		this.init();
	}

	init() {
		if (this.dataAtual) {
			this.mesAno = this.formatarDataMesAno(this.dataAtual);
		} else {
			this.dataAtual = new Date();
			this.mesAno = this.formatarDataMesAno(this.dataAtual);
		}
		this.autenticarUsuario();
		this.buscarPermissoes();
	}

	public async buscarPermissoes () {
		console.log("CAIU NA FUNÇÃO BUSCAR PERMISSOES");
    await this.permissoesProvider.buscar().then((result) => {
      this.permissoes = result;
    });

		if(Number(this.permissoes.dashboard_listar) === 1){
			console.log("BUSCOU A PERMISSÃO DASHBOARD LISTAR");
			this.buscaDadosConfig();
			this.exibirPagina = true;
		} else {
			console.log("NÃO deu certo para puxar a permissao dashboard listar");
			this.exibirPagina = false;
		}
	}

	public async autenticarUsuario() {

		await this.conexaoProvider.buscar().then((result) => {
			this.conexao = result;
		});

		await this.permissoesProvider.buscar().then((result) => {
			this.permissoes = result;
		  });

		const loadingRef = await this.loadingCtrl.create({
			message: "Carregando...",
		});
		loadingRef.present();

		const params: string = JSON.stringify({
			host: this.conexao.host,
			user: this.conexao.usuario,
			password: this.conexao.senha,
			database: this.conexao.banco,
			port: this.conexao.porta,
			login: this.permissoes.login,
			senhaUsuario: this.permissoes.password
		});

		try {
			this.api.autenticarUsuario(params).subscribe(
				(data) => {
					loadingRef.dismiss();
				},
				(error) => {
					loadingRef.dismiss();
					this.exibirPagina = false;

					this.permissoesProvider.buscaTokenAtivo().then((token) => {
						this.conexaoProvider.excluir("filial" + token);
						this.conexaoProvider.excluir("host");
						this.conexaoProvider.excluir("banco");
						this.conexaoProvider.excluir("usuario");
						this.conexaoProvider.excluir("porta");
						this.conexaoProvider.excluir("senha");
						this.conexaoProvider.excluir("nome");
					});
					this.showAlertMessage(
						"Alerta!",
						"Não foi possível efetuar o login. Por favor tente logar novamente através da aba de filiais!",
						error
					);

				}
			);
		} catch (error) {
			loadingRef.dismiss();
		}
	}

	canGoBack() {
		return this.ionRouterOutlet.canGoBack();
	}

	async buscaDadosConfig() {
		const loadingRef = await this.loadingCtrl.create({
			message: "Carregando...",
		});
		loadingRef.present();

		await this.conexaoProvider.buscar().then((result) => {
			this.conexao = result;
			this.nomeEmpresa = this.conexao.nome;
			this.buscarTotais();
			this.buscarFatTotal();
			this.buscarFatPorTipo();
			this.buscarFatPorSituacao();
			this.buscarFatPorPagamento();
			this.buscarVendasMes();
			this.buscarOsMes();
			this.buscarProdutosMaisVendidos();
			this.buscarProdutosMaisDevolvidos();
			this.buscarFatPorVendedor();
			// this.buscarCTP();
		});

		loadingRef.dismiss();
	}

	public async buscarTotais() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarTotais(params).subscribe(
				(data) => {
					if (data.length > 0) {
						this.osFaturadas = data[1].osEncerradas.toLocaleString("pt-BR", {
							style: "currency",
							currency: "BRL" }
						);
						this.vendasFaturadas = data[0].vendasFaturadas;
						this.totalItensVenda = data[0].qtdade;
						this.totalItensOs = data[1].qtdade;
						this.faturamentoTotal = 
							data[0].vendasFaturadas + data[1].osEncerradas;
						this.ticketMedio = (this.faturamentoTotal > 0 ? this.faturamentoTotal / (this.totalItensVenda + this.totalItensOs) : 0)
						this.faturamentoTotal =
							this.faturamentoTotal.toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL",
							});
						this.ticketMedio = this.ticketMedio.toLocaleString(
							"pt-BR",
							{ style: "currency", currency: "BRL" }
						);
						this.vendasFaturadas =
							this.vendasFaturadas.toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL",
							});
					} else {
						this.showToast(
							"bottom",
							"Esse token não tem permissão para acessar esse aplicativo",
							4000
						);
						this.navCtrl.navigateRoot("pes-filial");
					}
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar os totais!",
				error
			);
		}
	}

	public async buscarFatTotal() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarFaturamentoTotal(params).subscribe(
				(data) => {
					if (data.length > 0) {
						this.receitaTotal =
							data[0].totalRecebido.toLocaleString("pt-BR", {
								style: "currency",
								currency: "BRL",
							});
						this.despesaTotal = data[0].totalDespesa.toLocaleString(
							"pt-BR",
							{ style: "currency", currency: "BRL" }
						);
					} else {
						this.showToast(
							"bottom",
							"Esse token não tem permissão para acessar esse aplicativo",
							4000
						);
						this.navCtrl.navigateRoot("pes-filial");
					}
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar os totais!",
				error
			);
		}
	}

	public async buscarFatPorTipo() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarFaturamentoPorTipo(params).subscribe(
				(data) => {
					if (data.length > 0) {
						this.totalV = Number(data[0].tipoV).toFixed(2);
						this.totalCondicional = Number(
							data[0].tipoCondicional
						).toFixed(2);
						this.totalOrcamento = Number(
							data[0].tipoOrcamento
						).toFixed(2);
						this.totalBonificacao = Number(
							data[0].tipoBonificacao
						).toFixed(2);
						this.totalEntregaFutura = Number(
							data[0].tipoEntregaFutura
						).toFixed(2);
						this.totalD = Number(data[0].tipoD).toFixed(2);
						this.mostraGraficoFatTipo();
					} else {
						this.showToast(
							"bottom",
							"Esse token não tem permissão para acessar esse aplicativo",
							4000
						);
						this.navCtrl.navigateRoot("pes-filial");
					}
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar os totais!",
				error
			);
		}
	}

	public async buscarFatPorSituacao() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarFaturamentoPorSituacao(params).subscribe(
				(data) => {
					if (data.length > 0) {
						this.totalSitA = Number(
							data[0].sitAVenda + data[1].sitAOs
						).toFixed(2);
						this.totalSitC = Number(
							data[0].sitCVenda + data[1].sitCOs
						).toFixed(2);
						this.totalSitE = Number(
							data[0].sitEVenda + data[1].sitEOs
						).toFixed(2);
						this.mostraGraficoFatSituacao();
					} else {
						this.showToast(
							"bottom",
							"Esse token não tem permissão para acessar esse aplicativo",
							4000
						);
						this.navCtrl.navigateRoot("pes-filial");
					}
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar os totais!",
				error
			);
		}
	}

	public async buscarFatPorPagamento() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarFaturamentoPorPagamento(params).subscribe(
				(data) => {
					if (data.length > 0) {
						this.totalDinheiro = Number(
							data[0].formaDinheiroVenda + data[1].formaDinheiroOs
						).toFixed(2);
						this.totalCrediario = Number(
							data[0].formaCrediarioVenda +
								data[1].formaCrediarioOs
						).toFixed(2);
						this.totalCheque = Number(
							data[0].formaChequeVenda + data[1].formaChequeOs
						).toFixed(2);
						this.totalCartaoDebito = Number(
							data[0].formaCartaoDebitoVenda +
								data[1].formaCartaoDebitoOs
						).toFixed(2);
						this.totalCartaoCredito = Number(
							data[0].formaCartaoCreditoVenda +
								data[1].formaCartaoCreditoOs
						).toFixed(2);
						this.totalBoleto = Number(
							data[0].formaBoletoVenda + data[1].formaBoletoOs
						).toFixed(2);
						this.totalDepositoDinheiro = Number(
							data[0].formaDepositoDinheiroVenda +
								data[1].formaDepositoDinheiroOs
						).toFixed(2);
						this.totalDepositoCaixa = Number(
							data[0].formaDepositoCaixaVenda +
								data[1].formaDepositoCaixaOs
						).toFixed(2);
						this.totalPagamentoBoleto = Number(
							data[0].formaPagamentoBoletoVenda +
								data[1].formaPagamentoBoletoOs
						).toFixed(2);
						this.totalDepositoPixParticular = Number(
							data[0].formaDepositoPixParticularVenda +
								data[1].formaDepositoPixParticularOs
						).toFixed(2);
						this.totalDepositoPixLoja = Number(
							data[0].formaDepositoPixLojaVenda +
								data[1].formaDepositoPixLojaOs
						).toFixed(2);
						this.totalSiteBoleto = Number(
							data[0].formaSiteBoletoVenda +
								data[1].formaSiteBoletoOs
						).toFixed(2);
						this.mostraGraficoFatPagamento();
					} else {
						this.showToast(
							"bottom",
							"Esse token não tem permissão para acessar esse aplicativo",
							4000
						);
						this.navCtrl.navigateRoot("pes-filial");
					}
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar os totais!",
				error
			);
		}
	}

	public async buscarOsMes() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarOSPorMes(params).subscribe(
				(data) => {
					this.osMes = data;
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar as OS por mês!",
				error
			);
		}
	}

	public async buscarVendasMes() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarVendasPorMes(params).subscribe(
				(data) => {
					this.vendasMes = data;
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar as vendas por mês!",
				error
			);
		}
	}

	public async buscarProdutosMaisVendidos() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarProdutosMaisVendidos(params).subscribe(
				(data) => {
					this.produtosMaisVendidos = data;
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar as vendas por mês!",
				error
			);
		}
	}

	public async buscarProdutosMaisDevolvidos() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarProdutosMaisDevolvidos(params).subscribe(
				(data) => {
					this.produtosMaisDevolvidos = data;
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar as vendas por mês!",
				error
			);
		}
	}

	public async buscarFatPorVendedor() {
		try {
			const month = new Date(this.dataAtual).getMonth() + 1;
			const year = new Date(this.dataAtual).getFullYear();

			const params: string = JSON.stringify({
				host: this.conexao.host,
				user: this.conexao.usuario,
				password: this.conexao.senha,
				database: this.conexao.banco,
				port: this.conexao.porta,
				month,
				year,
			});

			this.api.buscarFaturamentoPorVendedor(params).subscribe(
				(data) => {
					this.vendedores = data;

					for (let i = 0; i < this.vendedores.length; i++) {
						this.nomesVendedores[i] = this.vendedores[i].vendedor;
						this.valoresPorVendedor[i] = Number(
							this.vendedores[i].totalFaturado
						).toFixed(2);
						this.backgroundColor[i] = "#15bf83";
						this.backgroundHoverColor[i] = "#15bf83";
					}
					this.mostraGraficoFatVendedor();
				},
				(error) => {
					this.showToast(
						"bottom",
						"Não foi possível conectar no Banco de Dados!",
						2000
					);
				}
			);
		} catch (error) {
			this.showAlertMessage(
				"Alerta!",
				"Algo inesperado aconteceu ao tentar buscar as vendas por mês!",
				error
			);
		}
	}

	// public async buscarCTP() {
	// 	try {
	// 		const month = new Date(this.dataAtual).getMonth() + 1;
	// 		const year = new Date(this.dataAtual).getFullYear();

	// 		const params: string = JSON.stringify({
	// 			host: this.conexao.host,
	// 			user: this.conexao.usuario,
	// 			password: this.conexao.senha,
	// 			database: this.conexao.banco,
	// 			port: this.conexao.porta,
	// 			month,
	// 			year,
	// 			situation: this.situacao,
	// 		});

	// 		this.api.buscarContasAPagarPorSemana(params).subscribe(
	// 			(data) => {
	// 				this.ctps = data;
	// 			},
	// 			(error) => {
	// 				this.showAlertMessage(
	// 					"Mensagem!",
	// 					"Não foi possível conectar no Banco de Dados!",
	// 					error
	// 				);
	// 			}
	// 		);
	// 	} catch (error) {
	// 		this.showAlertMessage(
	// 			"Alerta!",
	// 			"Algo inesperado aconteceu ao tentar listar as contas a pagar!",
	// 			error
	// 		);
	// 	}
	// }

	// alterouSituacao() {
	// 	this.situacao = !this.situacao;
	// 	this.buscarCTP();
	// }

	mostraGraficoFatVendedor() {
		this.barChart = new Chart(this.barCanvasFatVendedor.nativeElement, {
			type: "horizontalBar",
			data: {
				labels: this.nomesVendedores,
				datasets: [
					{
						label: "Faturamento",
						data: this.valoresPorVendedor,
						backgroundColor: this.backgroundColor,
						hoverBackgroundColor: this.backgroundHoverColor,
					},
				],
			},
			options: {
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
							},
						},
					],
					xAxes: [
						{
							ticks: {
								beginAtZero: true,
							},
						},
					],
				},
			},
		});
	}

	mostraGraficoFatPagamento() {
		this.doughnutChart = new Chart(
			this.doughnutCanvasFatPagamento.nativeElement,
			{
				type: "pie",
				data: {
					labels: [
						"Dinheiro",
						"Crediário",
						"Cheque",
						"Cartão de Débito",
						"Cartão de Crédito",
						"Boleto",
						"Depósito/Dinheiro",
						"Depósito à Vista/Caixa",
						"Pagamento em Boleto",
						"Depósito/Pix particular",
						"Depósito/Pix loja",
						"Site/Boleto - RM.Pag",
					],
					datasets: [
						{
							label: "# of Votes",
							data: [
								this.totalDinheiro,
								this.totalCrediario,
								this.totalCheque,
								this.totalCartaoDebito,
								this.totalCartaoCredito,
								this.totalBoleto,
								this.totalDepositoDinheiro,
								this.totalDepositoCaixa,
								this.totalPagamentoBoleto,
								this.totalDepositoPixParticular,
								this.totalDepositoPixLoja,
								this.totalSiteBoleto,
							],
							backgroundColor: [
								"#15bf83",
								"#21223a",
								"#ac9b00",
								"#070083",
								"#99007d",
								"#363636",
								"#8d0000",
								"#43004f",
								"#007180",
								"#007d0d",
								"#b07500",
								"#442800",
							],
							hoverBackgroundColor: [
								"#15bf83",
								"#21223a",
								"#ac9b00",
								"#070083",
								"#99007d",
								"#363636",
								"#8d0000",
								"#43004f",
								"#007180",
								"#007d0d",
								"#b07500",
								"#442800",
							],
						},
					],
				},
				options: {
					legend: {
						display: false,
					},
				},
			}
		);
	}

	mostraGraficoFatTipo() {
		this.doughnutChart = new Chart(
			this.doughnutCanvasFatTipo.nativeElement,
			{
				type: "pie",
				data: {
					labels: [
						"Vendas",
						"Condicionais",
						"Orçamentos",
						"Bonificações",
						"Entregas Futuras",
						"Devoluções",
					],
					datasets: [
						{
							label: "# of Votes",
							data: [
								this.totalV,
								this.totalCondicional,
								this.totalOrcamento,
								this.totalBonificacao,
								this.totalEntregaFutura,
								this.totalD,
							],
							backgroundColor: [
								"#15bf83",
								"#21223a",
								"#ac9b00",
								"#070083",
								"#99007d",
								"#8d0000",
							],
							hoverBackgroundColor: [
								"#15bf83",
								"#21223a",
								"#ac9b00",
								"#070083",
								"#99007d",
								"#8d0000",
							],
						},
					],
				},
				options: {
					legend: {
						display: false,
					},
				},
			}
		);
	}

	mostraGraficoFatSituacao() {
		this.doughnutChart = new Chart(
			this.doughnutCanvasFatSituacao.nativeElement,
			{
				type: "pie",
				data: {
					labels: ["Aberta", "Cancelada", "Emitida"],
					datasets: [
						{
							label: "# of Votes",
							data: [
								this.totalSitA,
								this.totalSitC,
								this.totalSitE,
							],
							backgroundColor: ["#15bf83", "#21223a", "#ac9b00"],
							hoverBackgroundColor: [
								"#15bf83",
								"#21223a",
								"#ac9b00",
							],
						},
					],
				},
				options: {
					legend: {
						display: false,
					},
				},
			}
		);
	}

	formatarDataMesAno(data: any) {
		let mes = data.getMonth() + 1;

		if (mes.toString() === 1) {
			mes = "Janeiro";
		} else if (mes.toString() === 2) {
			mes = "Fevereiro";
		} else if (mes.toString() === 3) {
			mes = "Março";
		} else if (mes.toString() === 4) {
			mes = "Abril";
		} else if (mes.toString() === 5) {
			mes = "Maio";
		} else if (mes.toString() === 6) {
			mes = "Junho";
		} else if (mes.toString() === 7) {
			mes = "Julho";
		} else if (mes.toString() === 8) {
			mes = "Agosto";
		} else if (mes.toString() === 9) {
			mes = "Setembro";
		} else if (mes.toString() === 10) {
			mes = "Outubro";
		} else if (mes.toString() === 11) {
			mes = "Novembro";
		} else if (mes.toString() === 12) {
			mes = "Dezembro";
		}

		const ano = data.getFullYear();
		return mes + "/" + ano;
	}

	mesProximo() {
		// eslint-disable-next-line no-eval
		this.dataAtual = new Date(
			this.dataAtual.getFullYear(),
			eval(1 + this.dataAtual.getMonth()),
			1
		);
		this.init();
	}

	mesAnterior() {
		// eslint-disable-next-line no-eval
		this.dataAtual = new Date(
			this.dataAtual.getFullYear(),
			eval(-1 + this.dataAtual.getMonth()),
			1
		);
		this.init();
	}

	async showToast(
		position: "top" | "bottom" | "middle",
		message: string,
		duration: number
	) {
		const toast = await this.toast.create({ message, duration, position });
		toast.present();
	}

	arredondar(d, casas) {
		const aux = Math.pow(10, casas);
		const num: number = Math.floor(d * aux) / aux;
		return num.toLocaleString("pt-BR", { minimumFractionDigits: 2 });
	}

	private async showAlertMessage(title: string, message: string, error: any) {
		try {
			console.log("Error: ", error);

			const alertRef = await this.alertCtrl.create({
				header: title,
				message,
				buttons: [
					{
						text: "Ok",
						role: "cancel",
					},
				],
			});
			alertRef.present();
		} catch (err) {
			alert(err);
		}
	}
}
