import { Component } from '@angular/core';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    { title: 'Inicio', url: '/home', icon: 'home' },
    { title: 'Clientes', url: '/pes-cliente', icon: 'people' },
    { title: 'Produtos', url: '/pes-produto', icon: 'cube' },
    { title: 'Vendas', url: '/pes-venda', icon: 'basket' },
    { title: 'Ordens de Serviço', url: '/pes-os', icon: 'build' },
    { title: 'Contas à Receber', url: '/pes-ctr', icon: 'cash' },
    { title: 'Contas à Pagar', url: '/pes-ctp', icon: 'cash' },
    { title: 'Caixa', url: '/pes-caixa', icon: 'trending-up' },
    { title: 'Filiais', url: '/pes-filial', icon: 'business' },
  ];

  constructor() {}
}
